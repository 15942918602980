import React from 'react';
import Modal from 'react-modal';

import { ModalContainer, ModalHeader, ModalBody, ModalFooter, Button, CancelBtn } from '../components/basic';

export default function ConfirmDeleteCompanyModal({
  show,
  setShow,
  deleteCompany,
  hide,
  company
}) {
  
  return show && <Modal isOpen={show} onRequestClose={hide}>
    <ModalContainer>
      <ModalHeader onClose={hide}>
        Delete client {company.name} 
      </ModalHeader>

      <ModalBody>
        Are you sure you want to delete this client? 
      </ModalBody>

      <ModalFooter>
        <CancelBtn type="button" onClick={hide}>Cancel</CancelBtn>
        <Button onClick={deleteCompany}>Delete</Button>
      </ModalFooter>
    </ModalContainer>
  </Modal>
}

import React from 'react';
import PropTypes from 'prop-types';

export default function Report({ active }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="44"
      viewBox="0 0 39 44"
    >
      <path
        fill={active ? '#FFB600' : '#cac7c6'}
        fillRule="nonzero"
        d="M3.9 0C1.755 0 0 1.8 0 4v32c0 2.2 1.755 4 3.9 4h9.902a16.21 16.21 0 0 1-2.098-7.703c-.127-6.978 4.228-13.015 10.626-15.39 3.169-1.177 6.146-1.104 8.87-.384v-2.867c0-1.06-.411-2.078-1.143-2.828l-9.414-9.656A3.853 3.853 0 0 0 17.885 0H3.9zm13.65 3l10.725 11H17.55V3zm9.75 17c-6.435 0-11.7 5.4-11.7 12s5.265 12 11.7 12S39 38.6 39 32s-5.265-12-11.7-12zm5.462 7.418c.493 0 .986.195 1.363.582a2.015 2.015 0 0 1 0 2.8l-6.638 6.81a1.614 1.614 0 0 1-2.324 0L21.45 33.8a2.015 2.015 0 0 1 0-2.8 1.896 1.896 0 0 1 2.73 0l2.145 2.2 5.07-5.2a1.907 1.907 0 0 1 1.367-.582z"
      />
    </svg>
  );
}

Report.propTypes = {
  active: PropTypes.bool,
};

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

import Overview from './views/overview';
import Upload from './views/upload';
import VatChecks from './views/vat-checks';
import NotFound from './views/not-found';
import SignInWebhook from './views/sign-in-webhook';
import NoRoleAssigned from './views/no-role-assigned';

import ProtectedRoute from './components/ProtectedRoute';
import Header from './components/header';

import logout from './logout';
import Remediation from './views/remediation';

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [showAccountPopup, setShowAccountPopup] = useState(false);
  const [onUserEvent, setOnUserEvent] = useState(true);

  // After 15 minutes of inactivity, logout user
  useEffect(
    function handleIDLESessionTimeout() {
      const timeout = setTimeout(() => {
        logout();
      }, 15 * 60 * 1000);

      return () => clearTimeout(timeout);
    },
    [onUserEvent]
  );

  return (
    <Router>
      <CaptureClick
        onClick={() => {
          setOnUserEvent(!onUserEvent);
          setShowAccountPopup(false);
        }}
      >
        <BlockUi
          tag='div'
          blocking={isLoading}
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <Header
            showAccountPopup={showAccountPopup}
            setShowAccountPopup={setShowAccountPopup}
          />
          <Main>
            <Switch>
              <ProtectedRoute
                admin
                exact
                path='/'
                setIsLoading={setIsLoading}
                component={Overview}
              />
              <ProtectedRoute
                path='/:company_id/upload'
                component={Upload}
                setIsLoading={setIsLoading}
              />
              <ProtectedRoute
                path='/:company_id/vat-checks/:report_id/remediation/:vat_id'
                component={Remediation}
                setIsLoading={setIsLoading}
              />
              <ProtectedRoute
                path='/:company_id/vat-checks/:report_id'
                component={VatChecks}
                setIsLoading={setIsLoading}
              />

              <Route path='/webhook/sign-in' component={SignInWebhook} />
              <Route path='/no-role-assigned' component={NoRoleAssigned} />
              <Route component={NotFound} />
            </Switch>
          </Main>
        </BlockUi>
      </CaptureClick>
    </Router>
  );
}

export default App;

const CaptureClick = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Main = styled.div`
  background-color: #f0f0f0;
  height: 100%;
  padding: 15px;
`;

import React from 'react';
import PropTypes from 'prop-types';

export default function Upload({ active }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="44"
      viewBox="0 0 35 34"
    >
      <g
        fill={active ? '#FFB600' : '#cac7c6'}
        fillRule="nonzero"
        opacity=".867"
      >
        <path d="M3.551 30.364H31.45V16.19H35V34H0V16.19h3.551z" />
        <path d="M15.484 9.948v17.576h2.02v-.051h2.012V9.897l5.939-.03L17.495 0v.064l-7.95 9.854z" />
      </g>
    </svg>
  );
}

Upload.propTypes = {
  active: PropTypes.bool,
};

import React from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import styled from 'styled-components';

import {backendUrl} from '../constants';
import { ModalHeader, ModalFooter, ModalBody, Button, ModalContainer } from './basic'; 

export default function FileUploadVerificationModal({
  parsedSheetResult,
  selectedFile,
  companyId,
  reports,
  setReports,
  setParsedSheetResult,   
  setIsLoading
}) {

  function uploadFile() {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('file', selectedFile);
      axios
        .post(`${backendUrl}/companies/${companyId}/reports/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(({ data: { addedReport } }) => {
          setReports([addedReport, ...reports]);
          setParsedSheetResult(null);
          setIsLoading(false);
        })
        .catch(() => {
          setParsedSheetResult(null);
          setIsLoading(false);
        });
  }


  const invalidVATNumbersFormat = parsedSheetResult ? parsedSheetResult.filter(r => !r.isValid) : [];
  const duplicateVATNumbers = parsedSheetResult ? Object.entries(parsedSheetResult.duplicates.VATNumbers) : [];
  const duplicateCompanyIdNumbers = parsedSheetResult ? Object.entries(parsedSheetResult.duplicates.companyIds) : [];
  const noCompanyId = parsedSheetResult ? parsedSheetResult.noCompanyId : [];
  const noVATNumber = parsedSheetResult ? parsedSheetResult.noVATNumber : [];
  const formatChange = parsedSheetResult ? parsedSheetResult.formatChange : 0;

  const totalWarnings = invalidVATNumbersFormat.length +
                     + duplicateVATNumbers.length
    + duplicateCompanyIdNumbers.length
    + noCompanyId.length
    + noVATNumber.length
    + (formatChange ? 1 : 0)

  return <Modal 
    isOpen={!!parsedSheetResult} 
    contentLabel="Validation modal"
    onRequestClose={() => setParsedSheetResult(null)} 
  >
    <ModalContainer>
        <ModalHeader onClose={() => setParsedSheetResult(null)} >
              {selectedFile && `Upload ${selectedFile.name}`}
            </ModalHeader>
            <ModalBody>
              <ModalWarningTitle>
                {parsedSheetResult &&
                  `This file contains ${totalWarnings} warning(s) `}
              </ModalWarningTitle>
              {totalWarnings > 0 && (
                <WarningContainer>
                  {
                      invalidVATNumbersFormat
                      .map((item, index) => (
                      <WarningRowContainer index={index} key={item.row} title="Invalid VAT ID numbers:" length={invalidVATNumbersFormat.length} text={`Row ${item.row}: Invalid VAT ID number format ${item.value}`} />
                      ))
                      .concat(
                        duplicateVATNumbers
                      .map(([VATNumber, rows], index) => (
                        <WarningRowContainer index={index} key={VATNumber + rows.join()} title="Duplicate VAT ID numbers:" length={duplicateVATNumbers.length} text={`Rows ${rows.join(', ')} have the same VAT ID number ${VATNumber}`}/>
                        )),
                      )
                      .concat(
                      duplicateCompanyIdNumbers
                      .map(([companyId, rows], index) => (
                      <WarningRowContainer
                        index={index}
                        key={companyId + rows.join()}
                        title="Duplicate company id numbers:"
                        length={duplicateCompanyIdNumbers.length}
                        text={`Rows ${rows.join(', ')} have the same company id ${companyId}`}
                      />
                        ))
                        )
                        .concat(noCompanyId.map((row, index) => (
                        <WarningRowContainer
                          index={index}
                          key={`no-company-id-${row}`}
                          title="Missing company ID's"
                          length={noCompanyId.length}
                          text={`Row ${row} does not have a company id`}
                        
                        />
                          )))
                          .concat(noVATNumber.map((row, index) => (
                          <WarningRowContainer
                            index={index}
                            key={`no-company-id-${row}`}
                            title="Missing VAT ID number"
                            length={noVATNumber.length}
                            text={`Row ${row} does not have a VAT ID number`}
                          />
                        )))
                        .concat(formatChange ? <WarningRow>{parsedSheetResult.formatChange} rows have been adjusted to a correct VAT ID number format for validation</WarningRow> : null)
                    }
                </WarningContainer>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  setParsedSheetResult(null);
                }}
              >
                Close
              </Button>
              <SpaceBetweenModelBtn />
              <Button onClick={() => uploadFile()}>Continue and validate</Button>
            </ModalFooter>
          </ModalContainer>
          </Modal>
}

function WarningRowContainer({index, key, length, text, title}) {
  let result = [];

  if(index === 0) 
    result.push(<WarningRow key={key}>{title}</WarningRow>);

  result.push(<WarningRow key={key}>{text}</WarningRow>);

  if(index === length - 1)
    result.push(<li></li>);

  return result;
}

const ModalWarningTitle = styled.div`
    font-size: 16px;
    color: #474747;
    margin-bottom: 10px;
  `;

const SpaceBetweenModelBtn = styled.div`
    width: 30px;
  `;

const WarningContainer = styled.ul`
    border: 1px solid #cccccc;
    padding: 10px;
  `;

const WarningRow = styled.li`
    color: #eb8c00;
    font-size: 16px;
  `;

import axios from 'axios';
import decode from 'jwt-decode';
import React, { useCallback, useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  A,
  Button,
  OtherStatusLabel,
  ReportTable,
  ReportTableCell,
  ReportTableCellEllipsis,
  ReportTableContent,
  ReportTableHeader,
  ReportTableRow,
  SpanFiller,
  Status,
} from '../../components/basic';
import SendEmailToClientWithResultModal from '../../components/SendEmailToClientWithResultModal';
import { backendUrl } from '../../constants';
import { isConsultant } from '../../helpers';

export default function VatCheck({
  match: {
    params: { report_id: reportId, company_id: companyId },
  },
  setIsLoading,
}) {
  const [reportDetails, setReportDetails] = useState();
  const [{ items: vatNumbers, count, offset, limit }, setVatNumbers] = useState({
    items: [],
    count: 0,
    offset: 0,
    limit: 20,
  });
  const [showEmailModal, setShowEmailModal] = useState(false);

  const token = localStorage.getItem('auth');
  const user = decode(token);

  const fetchReportDetail = useCallback(async () => {
    const { data } = await axios.get(`${backendUrl}/companies/${companyId}/reports/${reportId}`);
    setReportDetails(data);
  }, [companyId, reportId]);

  const fetchVats = useCallback(async () => {
    if (count === 0 || offset < count) {
      const { data } = await axios.get(`${backendUrl}/companies/${companyId}/reports/${reportId}/vats`, {
        params: { offset },
      });
      setVatNumbers({
        items: [...vatNumbers, ...data.items],
        count: data.count,
        offset: offset + limit,
        limit,
      });
    }
  }, [count, offset, companyId, reportId, vatNumbers, limit]);

  useEffect(() => {
    let id;
    const fetch = async () => {
      fetchReportDetail();
      id = setTimeout(fetch, 5000);
    };

    fetch();

    return () => {
      clearTimeout(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, reportId]);

  useEffect(() => {
    fetchReportDetail();
    fetchVats();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, reportId]);

  const onScroll = ({ target }) => {
    if (target.scrollHeight - target.scrollTop <= target.clientHeight) {
      fetchVats();
    }
  };

  const reloadVats = async () => {
    const { data } = await axios.get(`${backendUrl}/companies/${companyId}/reports/${reportId}/vats`, {
      params: { offset: 0 },
    });
    setVatNumbers({
      items: data.items,
      count: data.count,
      offset: 20,
      limit: 20,
    });
  };

  const fetchDownloadUrl = async () => {
    const {
      data: { url },
    } = await axios.get(`${backendUrl}/companies/${companyId}/reports/${reportId}/download`, { params: { offset: 0 } });

    const res = await (await fetch(url)).blob();
    const a = document.createElement('a');
    a.href = URL.createObjectURL(res);
    a.setAttribute('download', `result__${reportDetails.name}`);
    a.click();
  };

  const regenerateReport = async () => {
    await axios.post(`${backendUrl}/companies/${companyId}/reports/${reportId}/regenerate`);
    await fetchReportDetail();
  };

  return reportDetails?.total > 0 ? (
    <>
      {isConsultant(user) && (
        <SendEmailToClientWithResultModal
          companyId={companyId}
          showEmailModal={showEmailModal}
          setShowEmailModal={setShowEmailModal}
          reportId={reportId}
          setIsLoading={setIsLoading}
        />
      )}
      <VatChecksContainer>
        <VatChecksContainerInfo>
          <VATChecksInfoTitle>VAT Checks</VATChecksInfoTitle>
          <Pie
            options={{
              legend: { display: false },
            }}
            data={{
              labels: [
                'Valid percentage',
                'Corrected percentage',
                'Errors',
                'Invalid percentage',
                'Invalid format percentage',
                'Loading percentage',
              ],
              datasets: [
                {
                  data: [
                    ((reportDetails.valid / reportDetails.total) * 100).toFixed(2),
                    ((reportDetails.corrected / reportDetails.total) * 100).toFixed(2),
                    ((reportDetails.errors / reportDetails.total) * 100).toFixed(2),
                    ((reportDetails.invalid / reportDetails.total) * 100).toFixed(2),
                    ((reportDetails.invalidFormat / reportDetails.total) * 100).toFixed(2),
                    ((reportDetails.loading / reportDetails.total) * 100).toFixed(2),
                  ],
                  backgroundColor: ['#6fb24f', '#98d67a', '#e0301e', '#e0301e', '#e0821e', '#afa8a9'],
                  borderWidth: 0.5,
                },
              ],
            }}
          />

          <VATChecksSummary>
            From the {reportDetails.total} VAT ID numbers checked: <br />
            <ul>
              <li>{reportDetails.valid} VAT ID numbers are valid</li>
              <li>{reportDetails.invalid} VAT ID numbers are invalid (VIES)</li>
              <li>{reportDetails.invalidFormat} VAT ID numbers have invalid format</li>
              <li>{reportDetails.errors} VAT ID numbers have errors (VIES)</li>
              <li>{reportDetails.corrected} VAT ID numbers have been corrected</li>
              <li>{reportDetails.loading} VAT ID numbers are loading</li>
            </ul>
          </VATChecksSummary>
          <VATChecksStatus>
            <Status status={reportDetails.status}>{reportDetails.status}</Status>
          </VATChecksStatus>
        </VatChecksContainerInfo>
        <VatChecksContainerVats>
          <VatChecksContainerVatsHeader>
            <VATChecksVatsTitle>Report Preview</VATChecksVatsTitle>
            <Button onClick={reloadVats} style={{ height: '36px' }}>
              Reload list
            </Button>
          </VatChecksContainerVatsHeader>
          <ReportTable>
            <ReportTableHeader>
              <ReportTableCell width={'10%'}>Customer ID</ReportTableCell>
              <ReportTableCell width={'20%'}>Company Name</ReportTableCell>
              <ReportTableCell width={'40%'}>Company Information</ReportTableCell>
              <ReportTableCell width={'15%'}>Vat number</ReportTableCell>
              <ReportTableCell width={'15%'}>Status</ReportTableCell>
            </ReportTableHeader>
            <ReportTableContent onScroll={onScroll}>
              {vatNumbers.length === 0 ? (
                reportDetails?.loading > 0 ? (
                  <ReportTableRow>
                    <ReportTableCell>Loading vat numbers</ReportTableCell>
                  </ReportTableRow>
                ) : (
                  <ReportTableRow>
                    <ReportTableCell>No vat numbers found</ReportTableCell>
                  </ReportTableRow>
                )
              ) : (
                vatNumbers.map((vat) => (
                  <ReportTableRow>
                    <ReportTableCell width={'10%'}>
                      {vat.internalCompanyId ? vat.internalCompanyId : <SpanFiller>not set</SpanFiller>}
                    </ReportTableCell>
                    <ReportTableCell width={'20%'}>
                      <ReportTableCellEllipsis>
                        {vat.companyName || vat.initialCompanyName || <SpanFiller>not set</SpanFiller>}
                      </ReportTableCellEllipsis>
                    </ReportTableCell>
                    <ReportTableCell width={'40%'}>
                      <ReportTableCellEllipsis>
                        {vat.companyAddress ||
                          ((vat.initialAddress || vat.initialZipcode || vat.initialCity || vat.initialCountry) &&
                            `${vat.initialAddress} ${vat.initialZipcode} ${vat.initialCity} ${vat.initialCountry}`) || (
                            <SpanFiller>not set</SpanFiller>
                          )}
                      </ReportTableCellEllipsis>
                    </ReportTableCell>
                    <ReportTableCell width={'15%'}>{vat.initialVatNumber}</ReportTableCell>
                    <ReportTableCell width={'15%'}>
                      {!reportDetails.orbisEnabled ? (
                        <OtherStatusLabel
                          status={
                            vat.suggestions.length > 0
                              ? 'corrected'
                              : vat.isValid
                              ? 'ready'
                              : ['LOADING', 'PENDING'].includes(vat.viesStatus) ||
                                ['LOADING', 'PENDING'].includes(vat.orbisStatus)
                              ? 'loading'
                              : 'failed'
                          }
                        >
                          {' '}
                          {vat.suggestions.length > 0
                            ? 'corrected'
                            : vat.isValid
                            ? 'Valid'
                            : ['LOADING', 'PENDING'].includes(vat.viesStatus) ||
                              ['LOADING', 'PENDING'].includes(vat.orbisStatus) ||
                              vat.isValid == null
                            ? 'Loading'
                            : 'Invalid'}{' '}
                        </OtherStatusLabel>
                      ) : (
                        <Link to={`/${companyId}/vat-checks/${reportId}/remediation/${vat.id}`}>
                          {vat.orbisStatus !== 'DONE' || vat.suggestions.length > 0 ? (
                            <OtherStatusLabel
                              status={
                                vat.suggestions.length > 0
                                  ? 'corrected'
                                  : vat.isValid
                                  ? 'ready'
                                  : ['LOADING', 'PENDING'].includes(vat.viesStatus) ||
                                    ['LOADING', 'PENDING'].includes(vat.orbisStatus) ||
                                    vat.isValid == null
                                  ? 'loading'
                                  : 'failed'
                              }
                            >
                              {' '}
                              {vat.suggestions.length > 0
                                ? 'corrected'
                                : vat.isValid
                                ? 'Valid'
                                : ['LOADING', 'PENDING'].includes(vat.viesStatus) ||
                                  ['LOADING', 'PENDING'].includes(vat.orbisStatus) ||
                                  vat.isValid == null
                                ? 'Loading'
                                : 'Invalid'}{' '}
                            </OtherStatusLabel>
                          ) : (
                            <RemediationSpan>Go to remediation</RemediationSpan>
                          )}
                        </Link>
                      )}
                    </ReportTableCell>
                  </ReportTableRow>
                ))
              )}
            </ReportTableContent>
          </ReportTable>
          <ReportActions>
            <Actions>
              {reportDetails.shouldRegenerateFile ? (
                <Button onClick={() => regenerateReport()}>Regenerate report</Button>
              ) : (
                <>
                  {reportDetails.status === 'ready' && (
                    <>
                      <DownloadBtn onClick={fetchDownloadUrl}>Download full results</DownloadBtn>
                      {isConsultant(user) && (
                        <SendToClientBtn onClick={() => setShowEmailModal(true)}>Send to client</SendToClientBtn>
                      )}
                    </>
                  )}
                </>
              )}
            </Actions>
          </ReportActions>
        </VatChecksContainerVats>
      </VatChecksContainer>
    </>
  ) : (
    <span>Loading...</span>
  );
}

const RemediationSpan = styled.div`
  color: #eb8c00;
`;

const VatChecksContainer = styled.div`
  display: flex;
`;
const VatChecksContainerInfo = styled.div`
  background: white;
  margin-right: 20px;
  padding: 10px 26px;
`;
const VatChecksContainerVats = styled.div`
  background: white;
  flex: 1;
  padding: 10px 26px;
  & h1 {
    margin-bottom: 0;
  }
`;

const VatChecksContainerVatsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const ReportActions = styled.div`
  margin-top: 20px;
`;

const SendToClientBtn = styled(Button)`
  font-family: HelveticaNeue;
`;

const DownloadBtn = styled(A)`
  margin-right: 10px;
  font-size: 16px;
  cursor: pointer;
`;

const Actions = styled.div`
  text-align: right;
`;

const VATChecksSummary = styled.div`
  margin-top: 50px;
  font-size: 18px;
  color: #474747;
`;

const VATChecksStatus = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.h1`
  color: #474747;
  margin: 0;
  font-size: 36px;
  font-weight: 500;
`;

const VATChecksInfoTitle = styled(Title)`
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Charter';
`;

const VATChecksVatsTitle = styled(Title)`
  margin-bottom: 20px;
`;

import axios from 'axios';
import decode from 'jwt-decode';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ReportRowIcon from '../../assets/report-row-icon';
import {
  A,
  Box,
  Button,
  ReportTable,
  ReportTableCell,
  ReportTableHeader,
  ReportTableRow,
  Status,
} from '../../components/basic';
import FileUploadVerificationModal from '../../components/FileUploadVerificationModal';
import { backendUrl } from '../../constants';
import { isConsultant } from '../../helpers';
import * as XLSX from 'xlsx/xlsx.mjs';

export default function Upload({
  match: {
    params: { company_id: companyId },
  },
  setIsLoading,
}) {
  const [reports, setReports] = useState([]);
  // Result of the excel file after validation
  const [parsedSheetResult, setParsedSheetResult] = useState(null);
  // Excel file
  const [selectedFile, setSelectedFile] = useState(null);
  const [company, setCompany] = useState(null);

  // Reference to the <input type=file, which is hidden so we can show a button
  const fileInput = useRef(null);

  const token = localStorage.getItem('auth');
  const [user, setUser] = useState();

  const getReports = useCallback(
    () =>
      axios
        .get(`${backendUrl}/companies/${companyId}/reports`)
        .then(({ data: { reports } }) => {
          setReports(reports);
        }),
    [companyId]
  );

  useEffect(() => {
    setUser(decode(token));
  }, [token]);

  useEffect(() => {
    getReports();
  }, [getReports]);

  useEffect(() => {
    if (reports.length > 0) {
      // When reports changes (a loading report is added), fetch until it's ready
      const hasLoadingReports = reports.some(
        (report) => report.status.toLowerCase() === 'loading'
      );
      if (hasLoadingReports) {
        setTimeout(getReports, 5000);
      }
    }
  }, [getReports, reports]);

  useEffect(() => {
    if (isConsultant(user)) {
      axios
        .get(`${backendUrl}/companies/${companyId}`)
        .then(({ data: { company } }) => {
          setCompany(company);
        });
    }
  }, [companyId, user]);

  function validate() {
    // Only validate if user selected a file to upload
    if (!selectedFile) {
      return;
    }
    setIsLoading(true);
    const reader = new FileReader();

    reader.onload = function () {
      const result = reader.result;
      const workbook = XLSX.read(result, {
        type: 'binary',
      });
      const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
      let data = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 });

      // Use a web worker to avoid blocking the main thread
      // (Recursive function to avoid blocking the event loop was too slow)
      const sheetValidationWorker = new Worker(
        new URL('../../workers/validateSheet.worker.js', import.meta.url),
        { type: 'module' }
      );
      sheetValidationWorker.postMessage({
        rows: data,
      });

      sheetValidationWorker.onmessage = ({ data: { parsedResult } }) => {
        setIsLoading(false);
        setParsedSheetResult(parsedResult);
        sheetValidationWorker.terminate();
      };
    };

    reader.onerror = function () {
      console.log('error');
      setIsLoading(false);
    };

    reader.readAsBinaryString(selectedFile);
  }

  return (
    <div>
      {isConsultant(user) && (
        <b style={{ fontFamily: 'Charter' }}>{company && company.name}</b>
      )}
      <FileUploadVerificationModal
        parsedSheetResult={parsedSheetResult}
        setParsedSheetResult={setParsedSheetResult}
        selectedFile={selectedFile}
        companyId={companyId}
        setReports={setReports}
        reports={reports}
        setIsLoading={setIsLoading}
      />
      <Box border='#e15e01'>
        <b>1. Download the template</b>
        <Line>
          <p>
            Download the template (excel) and complete it according to the
            guidelines in the file.
          </p>
          <A
            href={`${backendUrl}/download/vat-template?authorization=${localStorage.getItem(
              'auth'
            )}`}
          >
            Download
          </A>
        </Line>
      </Box>
      <Box border='#f99800'>
        <b style={{ fontFamily: 'Charter' }}>2. Upload your file</b>
        <Line>
          <p>Once the file has been completed, please upload it here.</p>
          <Button primary onClick={() => fileInput.current.click()}>
            Select file
          </Button>
          <input
            style={{ display: 'none' }}
            ref={fileInput}
            type='file'
            accept='.xlsx'
            onChange={(e) => setSelectedFile(e.target.files[0])}
          />
          {selectedFile ? selectedFile.name : 'No file selected'}
          <Button onClick={validate}>Upload</Button>
        </Line>
      </Box>
      <ReportTable>
        <ReportTableHeader>
          <ReportTableCell width={'35%'}>Document title</ReportTableCell>
          <ReportTableCell width={'35%'}>Date</ReportTableCell>
          <ReportTableCell width={'10%'}>Status</ReportTableCell>
          <ReportTableCell width={'20%'}></ReportTableCell>
        </ReportTableHeader>
        {reports.length === 0 ? (
          <ReportTableRow>
            <ReportTableCell>No running reports yet</ReportTableCell>
          </ReportTableRow>
        ) : (
          reports.map((report) => (
            <ReportTableRow>
              <ReportTableCell width={'35%'}>
                <ReportFilenameWrapper>
                  <ReportRowIcon style={{ minWidth: '24px' }} />
                  <ReportFilename>{report.fileName}</ReportFilename>
                </ReportFilenameWrapper>
              </ReportTableCell>
              <ReportTableCell width={'35%'}>
                <ReportDate>
                  {moment(report.createdAt).format('dddd, MMMM Do YYYY, HH:mm')}
                </ReportDate>
              </ReportTableCell>
              <ReportTableCell width={'10%'}>
                <Status status={report.status}>{report.status}</Status>
              </ReportTableCell>
              <ReportTableCell width={'20%'}>
                <ReportTableButtonRight>
                  <Link
                    to={`/${companyId}/vat-checks/${report.id}`}
                    disabled={report.status === 'loading'}
                  >
                    <Button
                      // disabled={report.status === 'loading'}
                      onClick={() => {}}
                    >
                      Open report
                    </Button>
                  </Link>
                </ReportTableButtonRight>
              </ReportTableCell>
            </ReportTableRow>
          ))
        )}
      </ReportTable>
    </div>
  );
}

const ReportTableButtonRight = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const ReportFilenameWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const ReportDate = styled.div`
  width: 300px;
`;

const ReportFilename = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 5px;
`;
const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

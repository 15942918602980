import decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { Link, Route } from 'react-router-dom';
import styled from 'styled-components';

import AccountIcon from '../../assets/outline-account_circle.svg';
import OverviewIcon from '../../assets/overview-icon';
import pwcLogo from '../../assets/pwc-logo.png';
import logo from '../../assets/PwC_RoboVAT.png';
import RemediationIcon from '../../assets/remediation-icon';
import ReportIcon from '../../assets/report-icon';
import UploadIcon from '../../assets/upload-icon';
import { isConsultant } from '../../helpers';
import logout from '../../logout';

export default function HeaderComponent({
  showAccountPopup,
  setShowAccountPopup,
}) {
  const [user, setUser] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      const token = localStorage.getItem('auth');
      if (token) {
        setUser(decode(token));
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Route
      children={({ location }) => (
        <Header>
          <Brand
            onClick={() => {
              window.location.href = '/';
            }}
            src={logo}
            alt='robovat logo'
          />
          {location.pathname === '/' && (
            <IsAdminRoute>
              <IsAdminRouteTxt>Admin</IsAdminRouteTxt>
            </IsAdminRoute>
          )}
          <MenuContainer>
            <MenuLinks>
              {isConsultant(user) && (
                <MenuLink active={location.pathname === '/' ? 1 : 0} to='/'>
                  <OverviewIcon active={location.pathname === '/'} />
                  Overview
                </MenuLink>
              )}
              {location.pathname.includes('/upload') ||
              location.pathname.includes('/vat-checks') ? (
                <MenuLink
                  active={location.pathname.includes('/upload')}
                  to={`/${location.pathname.split('/')[1]}/upload`}
                >
                  <UploadIcon active={location.pathname.includes('/upload')} />
                  Upload
                </MenuLink>
              ) : (
                <DisabledMenuLink
                  active={location.pathname.includes('/upload')}
                >
                  <UploadIcon active={location.pathname.includes('/upload')} />
                  Upload
                </DisabledMenuLink>
              )}
              {location.pathname.includes('vat-checks') ||
              location.pathname.includes('remediation') ? (
                <MenuLink
                  active={
                    location.pathname.includes('/vat-checks') &&
                    !location.pathname.includes('remediation')
                  }
                  to={`/${location.pathname.split('/')[1]}/vat-checks/${
                    location.pathname.split('/')[3]
                  }`}
                >
                  <ReportIcon
                    active={
                      location.pathname.includes('/vat-checks') &&
                      !location.pathname.includes('remediation')
                    }
                  />
                  VAT Check Results
                </MenuLink>
              ) : (
                <DisabledMenuLink>
                  <ReportIcon />
                  VAT Check Results
                </DisabledMenuLink>
              )}
              <DisabledMenuLink
                active={location.pathname.includes('/remediation')}
              >
                <RemediationIcon
                  active={location.pathname.includes('/remediation')}
                />
                VAT Remediation
              </DisabledMenuLink>
            </MenuLinks>
            <RightSection>
              <Account
                onClick={(e) => {
                  e.stopPropagation();
                  setShowAccountPopup(true);
                }}
              >
                <StyledAccountIcon />
                <UserName> {user && user.given_name}</UserName>
                {showAccountPopup && (
                  <AccountPopup>
                    <Button onClick={logout}>Logout</Button>
                  </AccountPopup>
                )}
              </Account>
              <PwcLogo src={pwcLogo} />
            </RightSection>
          </MenuContainer>
        </Header>
      )}
    />
  );
}

const Button = styled.button`
  background-color: white;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
`;

const AccountPopup = styled.div`
  position: absolute;
  bottom: -40px
  left: 40px
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding: 10px 10px 10px 10px;
`;

const IsAdminRoute = styled.div`
  background-color: #474747;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
`;

const IsAdminRouteTxt = styled.div`
  font-family: Charter;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.03px;
  text-align: center;
  color: #ffffff;
  transform: rotate(-270deg);
`;

const UserName = styled.div`
  color: #474747;
  font-size: 15px;
  font-weight: 500;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
`;

const Account = styled.div`
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const StyledAccountIcon = styled(AccountIcon)``;

const MenuLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: ${({ active, theme: { colors } }) =>
    active ? colors.primary : colors.txt};
  opacity: 0.87;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  box-sizing: border-box;
  border-bottom: ${({ active, theme: { colors } }) =>
    active ? `4px solid ${colors.primary}` : '4px solid white'};
  height: 90px;
  margin-left: 20px;
  margin-right: 20px;
`;

const DisabledMenuLink = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  opacity: 0.87;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  box-sizing: border-box;
  border-bottom: ${({ active, theme: { colors } }) =>
    active ? `4px solid ${colors.primary}` : 'none'};
  height: 90px;
  margin-left: 20px;
  margin-right: 20px;
  color: ${({ active, theme: { colors } }) =>
    active ? colors.primary : '#cac7c6'};

  :hover {
    cursor: default;
  }
`;

const Header = styled.header`
  display: flex;
  background-color: white;
  font-family: Charter;
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 9;
`;

const MenuLinks = styled.div`
  display: flex;
`;

const PwcLogo = styled.img`
  width: 101px;
  height: 72px;
  object-fit: contain;
  margin-right: 30px;
`;

const Brand = styled.img`
  cursor: pointer;
  height: 90px;
  background-color: ${({ theme: { colors } }) => colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  font-family: Charter;
  font-size: 50px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  text-align: center;
  color: #ffffff;
  padding: 5px 10px;
`;

import axios from 'axios';
import decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { A, Button, OtherStatusLabel, ReportTableCell, SpanFiller, Status } from '../../components/basic';
import SendEmailToClientWithResultModal from '../../components/SendEmailToClientWithResultModal';
import { backendUrl } from '../../constants';
import { isConsultant } from '../../helpers';

export default function Remediation({
  match: {
    params: { report_id: reportId, company_id: companyId, vat_id: vatId },
  },
  setIsLoading,
}) {
  const [reportDetails, setReportDetails] = useState();
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [vat, setVat] = useState();

  const token = localStorage.getItem('auth');
  const user = decode(token);

  const [selectedSuggestions, setSelectedSuggestions] = useState([]);
  const [selectedCorrection, setSelectedCorrection] = useState();

  const fetchSuggestions = async () => {
    const { data } = await axios.get(`${backendUrl}/companies/${companyId}/reports/${reportId}/vats/${vatId}`);
    setVat(data);
    const isSelected = data.suggestions?.find((suggestion) => suggestion.isSelected);
    isSelected && setSelectedCorrection(isSelected.id);
  };

  const fetchReportDetails = async () => {
    const { data } = await axios.get(`${backendUrl}/companies/${companyId}/reports/${reportId}`);
    setReportDetails(data);
  };

  useEffect(() => {
    setSelectedCorrection();
    fetchSuggestions();

    fetchReportDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, reportId, vatId]);

  useEffect(() => {
    let id;
    const fetch = async () => {
      fetchSuggestions();
      fetchReportDetails();
      id = setTimeout(fetch, 5000);
    };

    fetch();

    return () => {
      clearTimeout(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, reportId, vatId]);

  const postVatForSuggestion = async () => {
    await axios.post(`${backendUrl}/companies/${companyId}/reports/${reportId}/vats/${vatId}/suggestions/vats`, {
      ids: selectedSuggestions,
    });

    setSelectedSuggestions([]);
    fetchSuggestions();
  };

  const postCorrection = async () => {
    await axios.post(`${backendUrl}/companies/${companyId}/reports/${reportId}/vats/${vatId}/suggestions/correction`, {
      suggestionId: selectedCorrection,
    });

    fetchReportDetails();
    fetchSuggestions();
  };

  return reportDetails && reportDetails.orbisEnabled && vat ? (
    <>
      {isConsultant(user) && (
        <SendEmailToClientWithResultModal
          companyId={companyId}
          showEmailModal={showEmailModal}
          setShowEmailModal={setShowEmailModal}
          reportId={reportId}
          setIsLoading={setIsLoading}
        />
      )}
      <VatChecksContainer>
        <VatChecksContainerInfo>
          <VATChecksInfoTitle>VAT Checks</VATChecksInfoTitle>
          <Pie
            options={{
              legend: { display: false },
            }}
            data={{
              labels: [
                'Valid percentage',
                'Corrected percentage',
                'Errors',
                'Invalid percentage',
                'Invalid format percentage',
                'Loading percentage',
              ],
              datasets: [
                {
                  data: [
                    ((reportDetails.valid / reportDetails.total) * 100).toFixed(2),
                    ((reportDetails.corrected / reportDetails.total) * 100).toFixed(2),
                    ((reportDetails.errors / reportDetails.total) * 100).toFixed(2),
                    ((reportDetails.invalid / reportDetails.total) * 100).toFixed(2),
                    ((reportDetails.invalidFormat / reportDetails.total) * 100).toFixed(2),
                    ((reportDetails.loading / reportDetails.total) * 100).toFixed(2),
                  ],
                  backgroundColor: ['#6fb24f', '#98d67a', '#e0301e', '#e0301e', '#e0821e', '#afa8a9'],
                  borderWidth: 0.5,
                },
              ],
            }}
          />
          <VATChecksSummary>
            From the {reportDetails.total} VAT ID numbers checked: <br />
            <ul>
              <li>{reportDetails.valid} VAT ID numbers are valid</li>
              <li>{reportDetails.invalid} VAT ID numbers are invalid (VIES)</li>
              <li>{reportDetails.invalidFormat} VAT ID numbers have invalid format</li>
              <li>{reportDetails.errors} VAT ID numbers have errors (VIES)</li>
              <li>{reportDetails.corrected} VAT ID numbers have been corrected</li>
              <li>{reportDetails.loading} VAT ID numbers are loading</li>
            </ul>
          </VATChecksSummary>
          <VATChecksStatus>
            <Status status={reportDetails.status}>{reportDetails.status}</Status>
          </VATChecksStatus>
        </VatChecksContainerInfo>
        <VatChecksContainerVats>
          <VATChecksVatsTitle>Remediation</VATChecksVatsTitle>
          <RemediationTable>
            <RemediationTableHeader>
              <RemediationTableHeaderTitle>Remediation</RemediationTableHeaderTitle>
              <RemediationTableHeaderSubTitle>
                Select the suggestions you'd like to get VAT numbers for
              </RemediationTableHeaderSubTitle>
              <RemediationTableHeaderNavigation>
                <SuggestionNavigationLinkInvalid
                  to={`/${companyId}/vat-checks/${reportId}/remediation/${vat.previousInvalid}`}
                  style={{
                    visibility: vat.previousInvalid ? 'visible' : 'hidden',
                  }}
                >
                  {'<<'}
                </SuggestionNavigationLinkInvalid>
                <SuggestionNavigationLink
                  to={`/${companyId}/vat-checks/${reportId}/remediation/${vat.previous}`}
                  style={{ visibility: vat.previous ? 'visible' : 'hidden' }}
                >
                  {'<'}
                </SuggestionNavigationLink>
                <span>
                  {vat.row + 1} of {reportDetails.total} VAT number(s)
                </span>
                <SuggestionNavigationLink
                  to={`/${companyId}/vat-checks/${reportId}/remediation/${vat.next}`}
                  style={{ visibility: vat.next ? 'visible' : 'hidden' }}
                >
                  {'>'}
                </SuggestionNavigationLink>
                <SuggestionNavigationLinkInvalid
                  to={`/${companyId}/vat-checks/${reportId}/remediation/${vat.nextInvalid}`}
                  style={{ visibility: vat.nextInvalid ? 'visible' : 'hidden' }}
                >
                  {'>>'}
                </SuggestionNavigationLinkInvalid>
              </RemediationTableHeaderNavigation>
            </RemediationTableHeader>
            <RemediationTableInitial>
              <span>Initial input</span>
              {/* <RemediationTableInitialTable> */}
              <RemediationTableInitialTableRow>
                <ReportTableCell width={'3%'}></ReportTableCell>
                <ReportTableCell width={'12%'}>Customer ID</ReportTableCell>
                <ReportTableCell width={'20%'}>Company Name</ReportTableCell>
                <ReportTableCell width={'35%'}>Company information</ReportTableCell>
                <ReportTableCell width={'20%'}>Initial VAT number</ReportTableCell>
                <ReportTableCell width={'8%'}>Status</ReportTableCell>
              </RemediationTableInitialTableRow>
              <RemediationTableInitialTableRow>
                <ReportTableCell width={'3%'}></ReportTableCell>
                <ReportTableCell width={'12%'}>
                  {vat.internalCompanyId ? vat.internalCompanyId : <SpanFiller>not set</SpanFiller>}{' '}
                </ReportTableCell>
                <ReportTableCell width={'20%'}>
                  {vat.companyName || vat.initialCompanyName || <SpanFiller>not set</SpanFiller>}{' '}
                </ReportTableCell>
                <ReportTableCell width={'35%'}>
                  {vat.companyAddress ||
                    ((vat.initialAddress || vat.initialZipcode || vat.initialCity || vat.initialCountry) &&
                      `${vat.initialAddress} ${vat.initialZipcode} ${vat.initialCity} ${vat.initialCountry}`) || (
                      <SpanFiller>not set</SpanFiller>
                    )}
                </ReportTableCell>
                <ReportTableCell width={'20%'}>{vat.initialVatNumber}</ReportTableCell>
                <ReportTableCell width={'8%'}>
                  {' '}
                  <OtherStatusLabel
                    status={
                      vat.suggestions.find((sug) => sug.isSelected)
                        ? 'corrected'
                        : vat.isValid
                        ? 'ready'
                        : ['LOADING', 'PENDING'].includes(vat.viesStatus) ||
                          ['LOADING', 'PENDING'].includes(vat.orbisStatus) ||
                          vat.isValid == null
                        ? 'loading'
                        : 'failed'
                    }
                  >
                    {' '}
                    {vat.suggestions.find((sug) => sug.isSelected)
                      ? 'Corrected'
                      : vat.isValid
                      ? 'Valid'
                      : ['LOADING', 'PENDING'].includes(vat.viesStatus) ||
                        ['LOADING', 'PENDING'].includes(vat.orbisStatus) ||
                        vat.isValid == null
                      ? 'Loading'
                      : 'Invalid'}{' '}
                  </OtherStatusLabel>
                </ReportTableCell>
              </RemediationTableInitialTableRow>
              {/* </RemediationTableInitialTable> */}
            </RemediationTableInitial>
            {vat.suggestions.length > 0 && (
              <>
                <RemediationTableSuggestions>
                  <span>VAT Corrections</span>
                  <RemediationTableSuggestionsInfo>
                    Select a VAT number that you'd like to use as a correction for the initial VAT number
                  </RemediationTableSuggestionsInfo>
                  {vat.suggestions.filter((suggestion) => suggestion.requestIdentifier).length > 0 ? (
                    <>
                      <RemediationTableInitialTableRow>
                        <ReportTableCell width={'3%'}></ReportTableCell>
                        <ReportTableCell width={'30%'}>Company Name</ReportTableCell>
                        <ReportTableCell width={'47%'}>Company information</ReportTableCell>
                        <ReportTableCell width={'20%'}>Suggested VAT number</ReportTableCell>
                      </RemediationTableInitialTableRow>
                      {vat.suggestions
                        .filter((suggestion) => suggestion.requestIdentifier)
                        .map((suggestion) => (
                          <RemediationTableSuggestionsRow>
                            <ReportTableCell width={'3%'}>
                              <input
                                type="checkbox"
                                checked={suggestion.id === selectedCorrection}
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    setSelectedCorrection(suggestion.id);
                                    // if (!selectedCorrection.includes(suggestion.id)) {
                                    //   setSelectedCorrection([...selectedCorrection, suggestion.id])
                                    // }
                                  } else {
                                    setSelectedCorrection(undefined);
                                    // setSelectedCorrection([...selectedCorrection.filter(selection => selection !== suggestion.id)]);
                                  }
                                }}
                              />
                            </ReportTableCell>
                            <ReportTableCellEllipsis width={'30%'}>
                              {suggestion.companyName || suggestion.initialCompanyName || (
                                <SpanFiller>not set</SpanFiller>
                              )}
                            </ReportTableCellEllipsis>
                            <ReportTableCellEllipsis width={'47%'}>
                              {suggestion.companyAddress ||
                                ((suggestion.initialAddresss ||
                                  suggestion.initialZipCode ||
                                  suggestion.initialCity ||
                                  suggestion.initialCountry) &&
                                  `${suggestion.initialAddress || ''} ${suggestion.initialZipCode || ''} ${
                                    suggestion.initialCity || ''
                                  } ${suggestion.initialCountry || ''}`) || <SpanFiller>not set</SpanFiller>}
                            </ReportTableCellEllipsis>
                            <ReportTableCell width={'20%'}>
                              {`${suggestion.countryCode}${suggestion.correctedVatNumber}`}
                            </ReportTableCell>
                          </RemediationTableSuggestionsRow>
                        ))}
                    </>
                  ) : (
                    <RemediationTableInitialTableRow>
                      <ReportTableCell width={'3%'}></ReportTableCell>
                      <ReportTableCell width={'97%'}>
                        Get vat numbers for suggestions first. This can be done by selecting VAT suggestions.
                      </ReportTableCell>
                    </RemediationTableInitialTableRow>
                  )}
                </RemediationTableSuggestions>
                <RemediationTableSuggestions>
                  <span>VAT Suggestions</span>
                  <RemediationTableSuggestionsInfo>
                    Select the suggestions you'd like to get VAT numbers for. Payment per line selected.
                  </RemediationTableSuggestionsInfo>
                  <RemediationTableInitialTableRow>
                    <ReportTableCell width={'3%'}>
                      <input
                        type="checkbox"
                        onClick={(e) => {
                          if (e.target.checked) {
                            setSelectedSuggestions(
                              vat.suggestions.filter((suggestion) => !suggestion.requestIdentifier).map((s) => s.id)
                            );
                          } else {
                            setSelectedSuggestions([]);
                          }
                        }}
                        checked={selectedSuggestions.length > 0}
                      />
                    </ReportTableCell>
                    <ReportTableCell width={'30%'}>Company Name</ReportTableCell>
                    <ReportTableCell width={'47%'}>Company information</ReportTableCell>
                    <ReportTableCell width={'20%'}>Suggested VAT number</ReportTableCell>
                  </RemediationTableInitialTableRow>
                  <RemediationTableOverflow>
                    {vat.suggestions
                      .filter((suggestion) => !suggestion.requestIdentifier)
                      .map((suggestion) => (
                        <RemediationTableSuggestionsRow key={suggestion.id}>
                          <ReportTableCell width={'3%'}>
                            <input
                              type="checkbox"
                              disabled={suggestion.viesStatus || suggestion.orbisStatus !== null}
                              checked={
                                selectedSuggestions.includes(suggestion.id) ||
                                suggestion.viesStatus ||
                                suggestion.orbisStatus !== null
                              }
                              defaultChecked={suggestion.viesStatus || suggestion.orbisStatus !== null}
                              onClick={(e) => {
                                if (e.target.checked) {
                                  if (!selectedSuggestions.includes(suggestion.id)) {
                                    setSelectedSuggestions([...selectedSuggestions, suggestion.id]);
                                  }
                                } else {
                                  setSelectedSuggestions([
                                    ...selectedSuggestions.filter((selection) => selection !== suggestion.id),
                                  ]);
                                }
                              }}
                            />
                          </ReportTableCell>
                          <ReportTableCellEllipsis width={'30%'}>
                            {suggestion.companyName || suggestion.initialCompanyName || (
                              <SpanFiller>not set</SpanFiller>
                            )}
                          </ReportTableCellEllipsis>
                          <ReportTableCellEllipsis width={'47%'}>
                            {suggestion.companyAddress ||
                              ((suggestion.initialAddresss ||
                                suggestion.initialZipCode ||
                                suggestion.initialCity ||
                                suggestion.initialCountry) &&
                                `${suggestion.initialAddress || ''} ${suggestion.initialZipCode || ''} ${
                                  suggestion.initialCity || ''
                                } ${suggestion.initialCountry || ''}`) || <SpanFiller>not set</SpanFiller>}
                          </ReportTableCellEllipsis>
                          <ReportTableCell width={'20%'}>
                            {(suggestion.orbisStatus == 'FAILED' && 'No VAT found on orbis') ||
                              (suggestion.viesStatus == 'FAILED' && 'Vies check failed, retrying...') ||
                              (!suggestion.requestIdentifier &&
                                suggestion.viesStatus === 'DONE' &&
                                'Orbis VAT was invalid on VIES.') ||
                              ((suggestion.orbisStatus !== null || suggestion.viesStatus !== null) && `Loading...`) ||
                              `Select to get suggestion`}
                          </ReportTableCell>
                        </RemediationTableSuggestionsRow>
                      ))}
                  </RemediationTableOverflow>
                </RemediationTableSuggestions>
              </>
            )}
          </RemediationTable>

          <ReportActions>
            <Actions
              style={{
                height: '34px',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                width={'unset'}
                onClick={() => postVatForSuggestion()}
                style={{
                  display: selectedSuggestions.length > 0 ? 'block' : 'none',
                }}
              >
                Get VAT numbers for selected suggestions
              </Button>
              <Button
                width={'unset'}
                onClick={() => postCorrection()}
                style={{
                  display: (
                    vat.suggestions.find((sug) => sug.isSelected)
                      ? selectedCorrection !== vat.suggestions.find((sug) => sug.isSelected).id
                      : selectedCorrection
                  )
                    ? 'block'
                    : 'none',
                }}
              >
                Correct initial VAT number with selected suggestion
              </Button>
            </Actions>
          </ReportActions>
        </VatChecksContainerVats>
      </VatChecksContainer>
    </>
  ) : (
    <span>Loading...</span>
  );
}

const SuggestionNavigationLink = styled(Link)`
  color: white;
  margin: 0 5px;
`;

const SuggestionNavigationLinkInvalid = styled(SuggestionNavigationLink)`
  color: red;
`;

const ReportTableCellEllipsis = styled(ReportTableCell)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const RemediationTable = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
`;

const RemediationTableInitial = styled.div`
  background: #efefef;
  padding: 6px 60px 10px 11px;

  & span {
    font-weight: bold;
  }
`;
const RemediationTableInitialTable = styled.div`
  ${
    '' /* display: flex;
  flex-direction: column; */
  }
`;

const RemediationTableOverflow = styled.div`
  overflow: scroll;
  height: 400px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const RemediationTableInitialTableRow = styled.div`
  display: flex;
  padding: 5px 0;
  align-items: center;
`;
const RemediationTableSuggestions = styled.div`
  ${
    '' /* & span {
    margin: 17px 63px 18px 12px;
  } */
  }
  padding: 6px 60px 10px 11px;

  & span:first-of-type {
    font-weight: bold;
  }
  ${
    '' /* display: flex;
  flex-direction: column; */
  }
`;

const RemediationTableSuggestionsRow = styled.div`
  display: flex;
  padding: 5px 0;
  ${
    '' /* display: flex;
  flex-direction: column; */
  }
`;
const RemediationTableSuggestionsInfo = styled.span`
  margin-left: 5px;
  opacity: 0.5;
`;

const RemediationTableHeader = styled.div`
  display: flex;
  background: #464646;
  padding: 12px 17px 12px 12px;
`;

const RemediationTableHeaderTitle = styled.div`
  color: white;
  margin-right: 10px;
`;

const RemediationTableHeaderSubTitle = styled.div`
  color: white;
  flex: 1;
  opacity: 0.5;
`;

const RemediationTableHeaderNavigation = styled.div`
  color: white;
  & span:nth-child(1) {
    margin-right: 5px;
    opacity: 0.7;
  }
  & span:nth-child(3) {
    margin-left: 5px;
    opacity: 0.7;
  }
`;

const VatChecksContainer = styled.div`
  display: flex;
`;
const VatChecksContainerInfo = styled.div`
  background: white;
  padding: 10px 26px;
  margin-right: 20px;
`;
const VatChecksContainerVats = styled.div`
  background: white;
  flex: 1;
  padding: 10px 26px;
`;

const ReportActions = styled.div`
  margin-top: 20px;
`;

const SendToClientBtn = styled(Button)`
  font-family: HelveticaNeue;
`;

const DownloadBtn = styled(A)`
  margin-right: 10px;
  font-size: 16px;
`;

const Actions = styled.div`
  text-align: right;
`;

const VATChecksSummary = styled.div`
  margin-top: 50px;
  font-size: 18px;
  color: #474747;
`;

const VATChecksStatus = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.h1`
  color: #474747;
  margin: 0;
  font-size: 36px;
  font-weight: 500;
`;

const VATChecksInfoTitle = styled(Title)`
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Charter';
`;

const VATChecksVatsTitle = styled(Title)`
  margin-bottom: 20px;
`;

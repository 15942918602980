import React from 'react';

export default function ReportRowIcon(props) {
  return (
    <svg
      width={24}
      height={27}
      viewBox="0 0 24 27"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"156A3E89-C157-4493-A3E8-91F15A785B2C"}</title>
      <g stroke="#474747" fill="none" fillRule="evenodd">
        <path strokeWidth={1.735} d="M1 1h17.448L23 6.068V26H1z" />
        <path strokeWidth={1.736} d="M6 8.539h13M6 14.5h13M6 19.5h9" />
      </g>
    </svg>
  )
}

import React from 'react';
import PropTypes from 'prop-types';

export default function OverviewIcon({ active }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="44"
      viewBox="0 0 50 43"
    >
      <g fill="none" fillRule="nonzero">
        <path
          fill={active ? '#FFB600' : '#474747'}
          d="M26.984 5.184H50v32.633H26.984zM0 5.184h23.016v32.633H0z"
        />
        <path
          fill="#FFF"
          d="M39.984 23.754v-1.526c.342-.17 1.321-1.339 1.423-2.252.27-.02.692-.268.816-1.246.067-.526-.199-.822-.36-.915 0 0 .401-.765.401-1.689 0-1.852-.724-3.431-2.281-3.431 0 0-.541-1.144-2.282-1.144-3.227 0-4.564 2.076-4.564 4.577 0 .842.402 1.689.402 1.689-.16.093-.427.388-.361.914.125.978.548 1.226.816 1.247.103.913 1.081 2.082 1.423 2.251v1.527c-.76 2.288-6.846.762-6.846 6.101h18.254c.005-5.34-6.08-3.814-6.841-6.103zM14.587 23.754v-1.526c.342-.17 1.322-1.339 1.423-2.252.27-.02.693-.268.816-1.246.067-.526-.198-.822-.36-.915 0 0 .402-.765.402-1.689 0-1.852-.725-3.431-2.282-3.431 0 0-.54-1.144-2.282-1.144-3.226 0-4.564 2.076-4.564 4.577 0 .842.402 1.689.402 1.689-.16.093-.427.388-.36.914.124.978.547 1.226.815 1.247.103.913 1.082 2.082 1.423 2.251v1.527c-.76 2.288-6.845.762-6.845 6.101h18.254c.003-5.34-6.082-3.814-6.842-6.103z"
        />
        <path
          fill={active ? '#FFB600' : '#474747'}
          stroke="#FFF"
          strokeWidth="2.4"
          d="M10.317 2h28.571v39H10.317z"
        />
        <path
          fill="#FFF"
          d="M27.183 23.755v-1.724c.388-.192 1.494-1.513 1.61-2.546.304-.023.782-.304.923-1.41.076-.594-.226-.928-.406-1.034 0 0 .453-.864.453-1.908 0-2.094-.82-3.88-2.58-3.88 0 0-.611-1.294-2.58-1.294-3.647 0-5.158 2.347-5.158 5.174 0 .952.453 1.908.453 1.908-.182.106-.483.44-.406 1.034.141 1.106.619 1.387.923 1.41.116 1.032 1.222 2.353 1.61 2.546v1.724c-.861 2.587-7.74.863-7.74 6.898h20.636c0-6.037-6.879-4.311-7.738-6.898z"
        />
      </g>
    </svg>
  );
}

OverviewIcon.propTypes = {
  active: PropTypes.bool,
};

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import AddIcon from '../../assets/baseline-add_circle-24px.svg';
import VertMenuIcon from '../../assets/baseline-more_vert-24px.svg';
import EditIcon from '../../assets/outline-edit-24px.svg';
import DeleteIcon from '../../assets/outline-delete-24px.svg';

import { backendUrl } from '../../constants';
import { Status, Box, IconBtn } from '../../components/basic';
import CompanyModal from '../../components/CompanyModal';
import ConfirmDeleteCompanyModal from '../../components/ConfirmDeleteCompanyModal';

const borderColors = ['#db536a', '#464646', '#d04a02', '#7d7d7d', '#ffb600'];
export default function Overview() {
  const [companies, setCompanies] = useState([]);
  const [showCompanyModal, _setShowCompanyModal] = useState(false);
  const [companyToEdit, setCompanyToEdit] = useState(null);
  const [showMenuId, setShowMenuId] = useState(null);
  const [showConfirmCompanyDeletion, setShowConfirmCompanyDeletion] =
    useState(false);
  const [companySelectedForDeletion, setCompanySelectedForDeletion] =
    useState(null);

  const getCompanies = () => {
    if (!showCompanyModal) {
      axios.get(`${backendUrl}/companies`).then(({ data: { companies } }) => {
        setCompanies(companies);
      });
    }
  };

  useEffect(getCompanies, [showCompanyModal]);
  useEffect(() => {
    if (!showCompanyModal) {
      setCompanyToEdit(null);
    }
  }, [showCompanyModal]);

  function setShowCompanyModal(state) {
    setShowMenuId(null);
    _setShowCompanyModal(state);
  }

  function confirmDeleteCompany(company) {
    // Confirmation
    setCompanySelectedForDeletion(company);
    setShowConfirmCompanyDeletion(true);
  }

  function deleteCompany(company) {
    setShowConfirmCompanyDeletion(false);
    axios
      .delete(`${backendUrl}/companies/${companySelectedForDeletion.id}`)
      .then(() => {
        setCompanySelectedForDeletion(null);
        getCompanies();
      });
  }

  function hideCompanyDeletionModal() {
    setCompanySelectedForDeletion(null);
    setShowConfirmCompanyDeletion(false);
  }

  return (
    <div onClick={() => setShowMenuId(null)}>
      <ConfirmDeleteCompanyModal
        show={showConfirmCompanyDeletion}
        hide={hideCompanyDeletionModal}
        deleteCompany={deleteCompany}
        company={companySelectedForDeletion}
      />
      <CompanyModal
        showCompanyModal={showCompanyModal}
        setShowCompanyModal={setShowCompanyModal}
        companyToEdit={companyToEdit}
        setCompanyToEdit={setCompanyToEdit}
      />
      <CompanyBox border='#ffb600'>
        <span>Add new client</span>
        <IconBtn onClick={() => setShowCompanyModal(true)} icon={AddIcon} />
      </CompanyBox>
      <Container>
        {companies.map((company, index) => (
          <CompanyBox border={borderColors[index % 5]} key={company.id}>
            <StyledLink to={`/${company.id}/upload`}>{company.name}</StyledLink>
            {company.status && (
              <Status status={company.status}>{company.status}</Status>
            )}
            <IconBtn
              onClick={(e) => {
                e.stopPropagation();
                setShowMenuId(company.id === showMenuId ? false : company.id);
              }}
              icon={VertMenuIcon}
            />

            {showMenuId === company.id && (
              <VertMenu>
                <VertMenuLine>
                  <IconBtn
                    onClick={(e) => {
                      setCompanyToEdit(company);
                      setShowCompanyModal(true);
                    }}
                    icon={EditIcon}
                  />
                </VertMenuLine>
                <VertMenuLine>
                  <IconBtn
                    onClick={(e) => {
                      confirmDeleteCompany(company);
                    }}
                    icon={DeleteIcon}
                  />
                </VertMenuLine>
              </VertMenu>
            )}
          </CompanyBox>
        ))}
      </Container>
    </div>
  );
}

const CompanyBox = styled(Box)`
  width: 350px;
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
  position: relative;
`;

const VertMenuLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
`;

const VertMenu = styled.div`
  position: absolute;
  top: 20px;
  right: -40px
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding: 10px 10px 0px 10px;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledLink = styled(Link)`
  color: #474747;
`;

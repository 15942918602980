import axios from 'axios';
import decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { backendUrl, redirectUrl } from '../../constants';
import { isConsultant } from '../../helpers';

function getCode({ location }) {
  if (location && location.search && location.search.includes('code')) {
    const code = location.search
      .substring(1)
      .split('&')
      .filter((s) => s.includes('code'))[0]
      .replace('code=', '');

    return code;
  }

  return null;
}

async function getToken(code) {
  try {
    const { data } = await axios.post(`${backendUrl}/auth/tokens`, {
      grant_type: 'authorization_code',
      code,
      redirect_uri: redirectUrl,
    });

    return data.idToken;
  } catch (error) {
    console.log(error);
  }
}

export default function SignInWebhook({ location }) {
  const [externalUserCompanyId, setExternalUserCompanyId] = useState();
  const [token, setToken] = useState();
  const [code, setCode] = useState(getCode({ location }));

  useEffect(() => {
    if (!code) {
      return;
    }
    getToken(code).then((x) => {
      if (x) {
        setToken(x);
      } else {
        setCode(null);
      }
    });
  }, [code, setToken]);

  useEffect(() => {
    if (!token) {
      return;
    }
    const user = decode(token);
    // Get company id if external user so we can redirect to company id
    // internal user has it's email on another property
    if (user && user.role) {
      let email;
      if (Array.isArray(user.role)) {
        if (user.role.includes('BE_RBV_INT_EXTERNAL')) {
          email = user.cloudEmail;
        } else if (user.role.includes('BE_RBV_EXT_EXTERNAL')) {
          email = user.email;
        }
      } else if (user.role === 'BE_RBV_INT_EXTERNAL') {
        email = user.cloudEmail;
      } else if (user.role === 'BE_RBV_EXT_EXTERNAL') {
        email = user.email;
      }

      if (email) {
        axios
          .post(`${backendUrl}/companies/user`, { email })
          .then(({ data }) => {
            if (data.company) {
              setExternalUserCompanyId(data.company.id);
            } else {
              setExternalUserCompanyId(null);
            }
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (!token && (code || localStorage.getItem('auth'))) {
    if (localStorage.getItem('auth')) {
      setToken(localStorage.getItem('auth'));
    }
    return <div>Redirecting...</div>;
  }

  if (!token) {
    return <Redirect to='/' />;
  }

  const user = decode(token);
  if (!user || !user.role) {
    return <Redirect to='/no-role-assigned' />;
  }
  localStorage.setItem('auth', token);

  if (isConsultant(user)) {
    return <Redirect to='/' />;
  } else if (
    user.role === 'BE_RBV_INT_EXTERNAL' ||
    user.role === 'BE_RBV_EXT_EXTERNAL' ||
    user.role.includes('BE_RBV_INT_EXTERNAL') ||
    user.role.includes('BE_RBV_EXT_EXTERNAL')
  ) {
    // External user
    if (externalUserCompanyId) {
      return <Redirect to={`/${externalUserCompanyId}/upload`} />;
    }
    if (externalUserCompanyId === null) {
      return <Redirect to='/no-role-assigned' />;
    }
  } else {
    return <Redirect to='/no-role-assigned' />;
  }

  return <div>Redirecting...</div>;
}

import * as React from "react"

function RemediationIcon({active}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={44}
      viewBox="0 0 40 44"
    >
      <path
        d="M4 0C1.794 0 0 1.794 0 4v32c0 2.206 1.794 4 4 4h14v-2.633l-.027-.066-6.989-5.895 1.38-4.683 9.538-.887L25.406 18H32v-6L20 0H4zm14 2.91L29 14H18V2.91zM28 22l-3.398 7.602-8.602.796 6.398 5.403L20.602 44 28 39.602 35.602 44l-2.204-8.2L40 30.399l-8.602-.796L28 22z"
        fill={active ? '#FFB600' : '#cac7c6'}
        fillRule="nonzero"
      />
    </svg>
  )
}

export default RemediationIcon

import decode from 'jwt-decode';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { isConsultant } from '../helpers';
import login from '../login';

export default function ProtectedRoute({
  component: Component,
  setIsLoading,
  admin,
  ...routeProps
}) {
  return (
    <Route
      {...routeProps}
      render={(props) => {
        const token = localStorage.getItem("auth");
        if (!token) {
          return login();
        }

        // If admin route, see if user has access
        if (admin) {
          const user = decode(token);
          if (!isConsultant(user)) {
            return <Redirect to="/webhook/sign-in" />;
          }
        }

        return <Component {...props} setIsLoading={setIsLoading} />;
      }}
    />
  );
}

const template = `<div style="font-family: georgia, serif;">Dear,

Thank you for using RoboVAT. 

Please find herewith the report and full list of results of the VAT ID number checks performed by RoboVAT. These checks have been done based on the received list of customer's info (VAT ID numbers, name and address) from [companyName]. 

Please note that some basic data cleaning has been performed on the VAT ID number format prior to the check with the VIES database.

The results have been split into valid or invalid VAT ID numbers and errors (if any) for your easy reference.

Should you have any further questions or remarks, please do not hesitate to contact the team.

Kind regards,

The RoboVAT team
</div>
<div style="font-family:Arial,Helvetica,sans-serif; font-size:13px;">
<b>Elian De Decker</b> 
PwC | Associate
Mobile: +32 470 63 01 26 
Email: <a href="mailto:elian.de.decker@pwc.com" target="_blank">elian.de.decker@pwc.com</a>
PricewaterhouseCoopers Business Advisory Services BV/SRL* 

<b>Lucas Desmarez</b> 
PwC | Manager
Mobile: +32 499 77 62 31 
Email: <a href="mailto:lucas.desmarez@be.pwc.com" target="_blank">lucas.desmarez@be.pwc.com</a> 
PricewaterhouseCoopers Business Advisory Services BV/SRL*  

* Firm legal information, click <a href="http://www.pwc.be/en/online/legal/pwc-business-advisory-services.html" ><span style="font-family:Georgia,serif">here</span></a> 
</div>`;

export default template;

import React,{useEffect, useState} from 'react';
import axios from 'axios';
import { Editor } from 'react-draft-wysiwyg';
import Modal from 'react-modal';
import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';

import defaultHtmlTemplate from '../assets/default-email-template';
import {backendUrl} from '../constants';
import { InputContainer, Input, Button, CancelBtn, ModalFooter, ModalContainer, ModalHeader, ModalBody} from '../components/basic';

function emailTransform(value, orig) {
    return orig.split(';');
}

let schema = yup.object().shape({
    email: yup.string().required(),
    subject: yup.string().required(),
    to: yup.array().transform(emailTransform).of(yup.string().email()),
    cc: yup.array().transform(emailTransform).of(yup.string().email())
  });

export default function SendEmailToClientWithResultModal({
  companyId,
  showEmailModal,
  setShowEmailModal,
  reportId,
  setIsLoading
}) {

  const [email, setEmail] = useState(null);
  const [to, setTo] = useState(null);
  const [cc, setCc] = useState(null);
  const [subject, setSubject] = useState(null);

  const defaultCCEmails = 'charles.robert@pwc.com;lucas.desmarez@pwc.com;marc.hoessels@pwc.com';

   useEffect(() => {
      axios.get(`${backendUrl}/companies/${companyId}`)
        .then(({data:{company}}) => {
          setCc(`${company.email_cc ? `${company.email_cc};` : ''}` + defaultCCEmails || defaultCCEmails);
          setTo(company.email_to || '');
          setSubject(`RoboVAT - VAT ID number check results`);
          const contentBlock = htmlToDraft(defaultHtmlTemplate.replace('[companyName]', company.name));
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const email = EditorState.createWithContent(contentState);
            setEmail(email);
          }
        })
    }, []);

  return (
      <Modal
        onRequestClose={() => setShowEmailModal(false)}
        isOpen={showEmailModal}
      >
        {email && <Formik
            initialValues={{ email, to, cc, subject }}
            validationSchema={schema}
            onSubmit={values => {
            setIsLoading(true);
            axios.post(`${backendUrl}/companies/${companyId}/reports/${reportId}/send-email`,{...values, email: draftToHtml(convertToRaw(values.email.getCurrentContent()))} )
            .then(() => {
              setIsLoading(false);
              setShowEmailModal(false);
            })
            .catch(() => {
              setIsLoading(false); 
            })

            }}
          >
            {() => (
            <Form style={{height: '100%'}}>
              <ModalContainer >
                <ModalHeader onClose={() => setShowEmailModal(false)}>
                  Send email to client
                </ModalHeader>
                <ModalBody>
                  <InputContainer label="To">
                    <Input type="text" name="to" />
                    <ErrorMessage name="to" component="div" />
                  </InputContainer>

                  <InputContainer label="Cc">
                    <Input type="text" name="cc" />
                    <ErrorMessage name="cc" component="div" />
                  </InputContainer>
                  
                  <InputContainer label="Subject">
                    <Input type="text" name="subject" />
                    <ErrorMessage name="subject" component="div" />
                  </InputContainer>

                  <Editor editorState={email} onEditorStateChange={(e) => setEmail(e)} />
                  <ErrorMessage name="email" component="div" />
              </ModalBody>

              <ModalFooter>
                <CancelBtn type="button" onClick={() => setShowEmailModal(false)}> Cancel</CancelBtn>
                <Button type="submit"> Send email</Button>
              </ModalFooter>
              </ModalContainer>
            </Form>
            )}
          </Formik>
          }
      </Modal>
)}



import React from 'react';
import Modal from 'react-modal';
import {Formik, Form, ErrorMessage} from 'formik'
import * as yup from 'yup';
import axios from 'axios';

import { ModalHeader, ModalFooter, InputContainer, Input, Button, CancelBtn, ModalBody, ModalContainer } from './basic';
import { backendUrl } from '../constants';

function emailTransform(value, orig) {
  return orig.split(';').map(email => email.trim());
}

let schema = yup.object().shape({
  companyName: yup.string().max(255).required(),
  to: yup.array().transform(emailTransform).of(yup.string().email()),
  cc: yup.array().transform(emailTransform).of(yup.string().email()),
  vatNumber: yup.string().required(),
  bvdApiKey: yup.string(),
});


export default function CompanyModal({
  showCompanyModal,
  setShowCompanyModal,
  companyToEdit
}) {
  return <Modal
      isOpen={showCompanyModal}
      onRequestClose={() => setShowCompanyModal(false)}
    >
      <Formik
        initialValues={{
          companyName: companyToEdit ? companyToEdit.name || '': '',
          cc: companyToEdit ? companyToEdit.email_cc || '': '',
          to: companyToEdit ? companyToEdit.email_to || '': '',
          vatNumber: companyToEdit ? companyToEdit.vat_number || '' : '',
          bvdApiKey: companyToEdit ? companyToEdit.bvd_api_key || '' : '',
        }}
        onSubmit={(company) => {
        const mappedCompany = {...company, cc: company.cc ? emailTransform('', company.cc).join(';') : '', to: company.to ? emailTransform('', company.to).join(';') : ''};

        if(companyToEdit) {
          axios.put(`${backendUrl}/companies/${companyToEdit.id}`, {company: mappedCompany})
          .then(() => {
            setShowCompanyModal(false);
          })
        } else {
          axios.post(`${backendUrl}/companies`, {company: mappedCompany})
            .then(() => {
              setShowCompanyModal(false);
            })
          } 
        }} 
        validationSchema={schema}
      >
      {() => (
        <Form style={{height: '100%'}}>
        <ModalContainer>
          <ModalHeader onClose={() => setShowCompanyModal(false)}>
           {companyToEdit ? 'Edit client' : 'Add new client'}
          </ModalHeader>
      
          <ModalBody>
            <InputContainer label="Company name">
              <Input type="text" name="companyName" />
              <ErrorMessage name="companyName" component="div" />
            </InputContainer>

            <InputContainer label="VAT ID number with country code prefix, without dots (eg BE123456789)">
              <Input type="text" name="vatNumber" />
              <ErrorMessage name="vatNumber" component="div" />
            </InputContainer>

            <InputContainer label="To (separate with ;)">
              <Input type="text" name="to" />
              <ErrorMessage name="to" component="div" />
            </InputContainer>

            <InputContainer label="Cc (separate with ;)">
              <Input type="text" name="cc" />
              <ErrorMessage name="cc" component="div" />
            </InputContainer>

            <InputContainer label="BvD API key">
              <Input type="text" name="bvdApiKey" />
              <ErrorMessage name="bvdApiKey" component="div" />
            </InputContainer>
          </ModalBody>

          <ModalFooter>
            <CancelBtn type="button" onClick={() => setShowCompanyModal(false)}>Cancel</CancelBtn>
              <Button type="submit">{companyToEdit ? 'Edit client': 'Add client'}</Button>
          </ModalFooter>
        </ModalContainer>
      </Form>
      )}
    </Formik>
    </Modal>
}

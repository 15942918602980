export function isConsultant(user) {
  if(!user || !user.role) {
    return false;
  }

  if(user.role === 'BE_RBV_INT_CONSULTANT' || user.role === 'BE_RBV_EXT_CONSULTANT') {
    return true;
  }

  if(Array.isArray(user.role)) {
    if(user.role.includes('BE_RBV_INT_CONSULTANT') || user.role.includes('BE_RBV_EXT_CONSULTANT')) {
      return true
    }
  }

  return false;
}

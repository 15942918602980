import axios from 'axios';

import { backendUrl, signOutUrl } from './constants';

export default function logout() {
  // We invalidate token before expiration
  axios
    .post(`${backendUrl}/auth/invalidate-token`, {
      token_id: localStorage.getItem("auth"),
    })
    .then(() => {
      localStorage.clear();
      window.location.href = signOutUrl;
    });
}
